import logo from './molecly.svg';
import './App.css';

function App() {
  return (
    
    <div>
        <img src={logo} className="App-logo" alt="logo" />
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: "90vh"}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: "10px", alignItems: 'center'}}>
        <div style={{fontSize: '6vw'}}>
          Work in progress
        </div>
        <div style={{fontSize: '1rem'}}>
          Our exciting website will launch soon.
        </div>
        <div>
          Come back and check it out.
        </div>

        <div style={{fontSize: '1rem'}}>
          Or contact us via email at <a href={'mailto:app@molecly.com'}>app@molecly.com</a>.
        </div>
        </div>
        </div>
    </div>
  );
}

export default App;
